// required　modules
// --------------------------------------------------
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AccountImage from "../../../images/account_circle-24px.svg";
import DialogMedical from "../../molecules/dialogs/DialogMedical";
import DialogCancelConfirm from "../../molecules/dialogs/DialogConfirm";
import { BillingCategorySelect } from "../../molecules/appointment/BillingCategorySelect";
import { DrugQuantitySelect } from "../../molecules/appointment/DrugQuantitySelect";
import { DrugNameInput } from "../../organisms/appointment/DrugNameInput";
import { MemoTextarea } from "../../molecules/appointment/MemoTextarea";
import { DrugInput } from "../../molecules/appointment/DrugInput";
import { MedicalFeeInput } from "../../molecules/appointment/MedicalFeeInput";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import isNumber from "lodash/isNumber";
// personal modules
// --------------------------------------------------
import LinkA from "../../atoms/links/LinkA";
import { japaneseList } from "../../../Resources/japaneseList";
import { getAge, toHalfWidthNumbers } from "../../../Utils/DisplayLogic";
import NumberFormat from "react-number-format";
import { client, UpdateMedicalTreatment } from "../../../graphql";
import DialogError from "../../molecules/dialogs/DialogError";
import {
  PAYMENT_METHOD,
  MEDICAL_METHOD,
  MEDICAL_MENU,
  BILLING_CATEGORY_OPTION,
  PRESCRIPTION_DRUG_INPUT_METHOD,
} from "../../../Utils/const";
const Resources = japaneseList.Components.Appointment;
const ResourcesPayment = japaneseList.Components.Payment;
const ResourcesPaymentList =
  japaneseList.Components.Payment.HospitalPaymentList;

const memoMaxlength = 500;
const drugNameMaxLengthEWP = 15;
const drugNameMaxLength = 30;
const drugQuantityMaxLength = 20;
const medicalFeeMax = 9999999;
const selectableMaxDrugsQuantity = 6;
const maleGender = Resources.StatusChangeDialog.genderMale;

// style
// --------------------------------------------------
const styles = (theme) => ({
  buttonOk: {
    color: theme.button.ok.color,
    marginRight: "0px",
    backgroundColor: theme.button.ok.backgroundColor,
    width: "120px",
    height: "40px",
    boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
    "&:hover": {
      backgroundColor: theme.button.ok.backgroundColorHover,
    },
    fontWeight: "bold",
  },
  buttonCancel: {
    backgroundColor: "gray",
    color: "white",
    width: "120px",
    marginRight: "20px",
    height: "40px",
    boxShadow: "0px 2px 0px #505050",
    "&:hover": {
      backgroundColor: theme.button.cancel.backgroundColorHover,
    },
    fontWeight: "bold",
  },
  buttonDisable: {
    backgroundColor: "#eee",
    pointerEvents: "none",
    color: "white",
    "&:hover": {
      backgroundColor: "#eee",
    },
    width: "120px",
    height: "40px",
    fontWeight: "bold",
  },
  tableStyle: {
    borderCollapse: "collapse",
    width: "310px",
    tableLayout: "auto",
  },
  thBirthDay: {
    width: "98px",
  },
  thAge: {
    width: "26px",
  },
  thGender: {
    width: "48px",
  },
  thPhonenumber: {
    width: "120px",
  },
  thStyle: {
    textAlign: "center",
    borderTop: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    backgroundColor: "#eee",
    fontSize: "12px",
    color: "#808080",
  },
  trContent: {
    height: "33px",
  },
  tdStyle: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    color: "#393939",
    whiteSpace: "nowrap",
  },
  tdStyleGenderMale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  tdStyleGenderFemale: {
    padding: "10px",
    textAlign: "center",
    borderBottom: "1px solid #ccc",
    borderLeft: "1px solid #ccc",
    borderRight: "1px solid #ccc",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  containerTable: {
    display: "flex",
    boxSizing: "border-box",
    flexWrap: "nowrap",
    width: "100%",
    justifyContent: "space-between",
    marginTop: "8px",
  },
  buttonCancelApp: {
    backgroundColor: "#4164b5",
    color: "white",
    "&:hover": {
      backgroundColor: "#4164b5",
    },
  },
  containerBorder: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
    marginTop: "20px",
  },
  containerBorderConfirm: {
    borderBottom: "#ccc solid 1px",
    borderRight: "#ccc solid 1px",
    borderLeft: "#ccc solid 1px",
    width: "100%",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "80px auto",
    borderTop: "#ccc solid 1px",
  },
  titleItem: {
    backgroundColor: "#eee",
    padding: "0 10px",
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    color: "#808080",
  },
  contentItem: {
    padding: "10px 20px",
    fontSize: "14px",
    minHeight: "41px",
    lineHeight: "21px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
  contentItemMemoInput: {
    padding: "10px 20px",
    fontSize: "14px",
  },
  inputContent: {
    height: "34px",
    fontSize: "14px",
    backgroundColor: theme.color.primaryVariant3,
    border: `1px solid #DDDDDD`,
    borderRadius: "3px",
  },
  textAreaMemo: {
    height: "64px",
    backgroundColor: theme.color.primaryVariant3,
    border: `1px solid #DDDDDD`,
    borderRadius: "3px",
  },
  maxHeightDialog: {
    maxHeight: "96%",
  },
  flexContainerAction: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: 0,
  },
  linkAStyle: {
    fontSize: "14px",
    color: "#2563C0",
    marginTop: "9px",
    marginBottom: "9px",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none",
    },
  },
  gridContentPatient: {
    marginTop: "20px",
  },
  gridAvatar: {
    marginRight: "12px",
  },
  textName: {
    width: "calc(100% - 102px);",
  },
  textGiveName: {
    fontSize: "16px",
    fontWeight: "bold",
    wordBreak: "break-all",
  },
  textGiveNameKana: {
    fontSize: "12px",
    wordBreak: "break-all",
  },
  textQuestion: {
    fontSize: "14px",
    marginTop: "25px",
    marginBottom: "15px",
  },
  textMedicalFeeNote: {
    marginLeft: "15px",
    fontSize: "11px",
    color: "#808080",
  },

  displayValidateError: {
    margin: "auto",
    color: "#EF5E5E",
    fontWeight: "bold",
  },

  hasError: {
    background: "#FFF1F2 0% 0% no-repeat padding-box",
  },

  selfFundedNote: {
    margin: "10px 0",
    color: "#EF5E5E",
    background: "#fff1f2",
    padding: "5px 10px",
    borderRadius: "3px",
    fontSize: "14px",
  },
});

// class define
// --------------------------------------------------
class MedicalFeeDialog extends Component {
  // default state
  // --------------------------------------------------
  static defaultProps = {
    open: false,
    onClose: function () {},
    maxWidth: "md",
    handleClickOk: function () {},
    handleClickCancel: function () {},
  };

  // constructor
  // --------------------------------------------------
  constructor(props) {
    super(props);
    this._isMounted;
    this.state = {
      isOpenCancelDialogConfirm: false,
      checkMemoInput: true,
      checkMedicalFeeZero: true,
      checkMedicalFeeLength: true,
      checkDrugNameEmpty: true,
      checkDrugNameLength: true,
      checkDrugQuantityEmpty: true,
      checkDrugQuantityLength: true,
      checkDrugQuantityValid: true,
      checkMemoEmpty: true,
      isDrugNameInputDisabled: false,
      isDrugQuantitySelectDisabled: false,
      confirmation: false,
      enableSubmitButton: this.isEnableSubmitButtonOnInit(),
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.appointment.contractPlan) this.setDrugState();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setDrugState = () => {
    const { inputMethod, fixedDrugsName } =
      this.props.appointment.contractPlan.plan.prescriptionDrug;

    const contentState = {
      isDrugNameInputDisabled: false,
      isDrugQuantitySelectDisabled: false,
    };

    const drugsInfo = {
      drugsName: this.props.appointment.drugsName || "",
      drugsQuantity: [...Array(selectableMaxDrugsQuantity + 1)]
        .map((_, i) => i.toString())
        .includes(this.props.appointment.drugsQuantity)
        ? this.props.appointment.drugsQuantity
        : "",
    };

    switch (inputMethod) {
      case PRESCRIPTION_DRUG_INPUT_METHOD.FIXED:
        drugsInfo.drugsName = fixedDrugsName;
        contentState.isDrugNameInputDisabled = true;
        break;
      case PRESCRIPTION_DRUG_INPUT_METHOD.NONE:
        drugsInfo.drugsName = "";
        drugsInfo.drugsQuantity = "0";
        contentState.isDrugNameInputDisabled = true;
        contentState.isDrugQuantitySelectDisabled = true;
        break;
      default:
        break;
    }

    if (this._isMounted) {
      this.setState({ ...contentState });
      this.props.handleSetDrugsInfo(drugsInfo, this.handleEnableSubmitButton);
    }
  };

  isEnableSubmitButtonOnInit = () => {
    return this.props.appointment.menu.paymentMethod ===
      PAYMENT_METHOD.BILLING_ENTERPRISE
      ? this.validateBillingEnterpriseFormWhenKeyUp()
      : this.validateFormWhenKeyUp();
  };

  handleEnableSubmitButton = (e) => {
    this.props.appointment.menu.paymentMethod ===
    PAYMENT_METHOD.BILLING_ENTERPRISE
      ? this.validateBillingEnterpriseFormWhenKeyUp()
      : this.validateFormWhenKeyUp();
  };

  validateFormWhenKeyUp = () => {
    let validationResult = {
      checkMedicalFeeZero:
        !this.props.appointment.requireMedicalExpense ||
        (toHalfWidthNumbers(
          this.props.appointment.payment.medicalExpense.toString()
        ) || 0) !== 0,
      checkDrugNameEmpty:
        !this.props.appointment.requireDrugName ||
        (this.props.appointment.drugsName &&
          this.props.appointment.drugsName.trim().length > 0),
      checkDrugQuantityEmpty:
        !this.props.appointment.requireDrugQuantity ||
        (this.props.appointment.drugsQuantity &&
          this.props.appointment.drugsQuantity.trim().length > 0),
    };
    let enableSubmitButton = !Object.values(validationResult).some(
      (x) => x === false
    );
    this.setState({
      enableSubmitButton,
    });
    return enableSubmitButton;
  };

  validateBillingEnterpriseFormWhenKeyUp = () => {
    const validationResult = {
      checkDrugQuantityEmpty: this.props.appointment.drugsQuantity,
      checkBillingCategoryEmpty: this.props.appointment.billingCategory,
    };
    const enableSubmitButton = !Object.values(validationResult).some((x) => !x);
    this.setState({
      enableSubmitButton,
    });
    return enableSubmitButton;
  };

  validateForm = () => {
    let validationResult = {
      checkMemoInput:
        !this.props.appointment.memo ||
        this.props.appointment.memo.trim().length <= memoMaxlength,
      checkMedicalFeeZero:
        !this.props.appointment.requireMedicalExpense ||
        (this.props.appointment.payment.medicalExpense || 0) !== 0,
      checkMedicalFeeLength:
        !this.props.appointment.requireMedicalExpense ||
        this.props.appointment.payment.medicalExpense <= medicalFeeMax,
      checkDrugNameLength:
        !this.props.appointment.requireDrugName ||
        (this.props.appointment.drugsName &&
          this.props.appointment.drugsName.trim().length <= drugNameMaxLength),
      checkDrugNameEmpty:
        !this.props.appointment.requireDrugName ||
        (this.props.appointment.drugsName &&
          this.props.appointment.drugsName.trim().length > 0),
      checkDrugQuantityLength:
        !this.props.appointment.requireDrugQuantity ||
        (this.props.appointment.drugsQuantity &&
          this.props.appointment.drugsQuantity.trim().length <=
            drugQuantityMaxLength),
      checkDrugQuantityEmpty:
        !this.props.appointment.requireDrugQuantity ||
        (this.props.appointment.drugsQuantity &&
          this.props.appointment.drugsQuantity.trim().length > 0),
    };
    let enableSubmitButton = !Object.values(validationResult).some(
      (x) => x === false
    );
    this.setState({
      ...validationResult,
      enableSubmitButton,
    });
    return enableSubmitButton;
  };

  validateBillingEnterpriseForm = () => {
    const validationResult = {
      checkDrugQuantityValid:
        this.props.appointment.billingCategory ===
        BILLING_CATEGORY_OPTION.CONSULTATION
          ? this.props.appointment.drugsQuantity === "0"
          : true,
      checkDrugNameEmpty:
        this.props.appointment.drugsQuantity > "0"
          ? this.props.appointment.drugsName
          : true,
      checkDrugNameLength: this.props.appointment.drugsName
        ? this.props.appointment.drugsName.trim().length <= drugNameMaxLengthEWP
        : true,
      checkMemoInput: this.props.appointment.memo
        ? this.props.appointment.memo.trim().length <= memoMaxlength
        : true,
    };
    const enableSubmitButton = !Object.values(validationResult).some((x) => !x);
    this.setState({
      ...validationResult,
      enableSubmitButton,
    });
    return enableSubmitButton;
  };

  validateAndSubmitForm = () => (e) => {
    if (
      this.props.appointment.menu.paymentMethod ===
      PAYMENT_METHOD.BILLING_ENTERPRISE
        ? this.validateBillingEnterpriseForm()
        : this.validateForm()
    ) {
      this.setState({
        confirmation: true,
      });
    }
  };

  handleCancelDialogConfirm = () => {
    this.setState({
      isOpenCancelDialogConfirm: false,
    });
  };

  handleClickButtonOK = async () => {
    this.props.onDialogLoading(true);
    try {
      const isBillingEnterprise =
        this.props.appointment.menu.paymentMethod ===
        PAYMENT_METHOD.BILLING_ENTERPRISE;
      const variables = {
        patientId: this.props.appointment.patient.patientId,
        createTime: this.props.appointment.createTime,
        memo: this.props.appointment.memo,
      };
      const { data, errors } = await client.mutate({
        mutation: UpdateMedicalTreatment,
        variables: isBillingEnterprise
          ? {
              ...variables,
              drugsQuantity: this.props.appointment.drugsQuantity,
              billingCategory: this.props.appointment.billingCategory,
              drugsName: this.props.appointment.drugsName,
              medicalExpense: 0,
            }
          : {
              ...variables,
              medicalExpense: this.props.appointment.payment.medicalExpense
                ? this.props.appointment.payment.medicalExpense
                : undefined,
              drugsName:
                this.props.appointment.requireDrugName === true
                  ? this.props.appointment.drugsName
                  : undefined,
              drugsQuantity:
                this.props.appointment.requireDrugQuantity === true
                  ? this.props.appointment.drugsQuantity
                  : undefined,
            },
      });
      if (errors) {
        errors.forEach((error) => {
          let errorResource =
            ResourcesPaymentList.render.ErrorResources.Errors.find(
              (item) => item.code === error.errorType
            );
          if (!Boolean(errorResource)) {
            this.ErrorMessage =
              ResourcesPaymentList.render.ErrorResources.InternalError;
          } else {
            this.ErrorMessage = errorResource.text;
          }
        });
        this.props.onDialogLoading(false);
        this.setState({
          isOpenErrorDialog: true,
        });
        return;
      }
      if (data) {
        await this.props.loadAppointmentList();
        this.props.handleCloseMedicalFeeDialog();
        this.props.onDialogLoading(false);
      }
    } catch (errors) {
      console.log("errors", errors);
      if (errors instanceof Error) {
        let errorResource =
          ResourcesPaymentList.render.ErrorResources.Errors.find(
            (item) => item.code === "E06"
          );
        this.ErrorMessage = errorResource && errorResource.text;
        this.props.onDialogLoading(false);
        this.setState({
          isOpenErrorDialog: true,
        });
        return;
      }
      errors.forEach((error) => {
        let errorResource =
          ResourcesPaymentList.render.ErrorResources.Errors.find(
            (item) => item.code === error.errorType
          );
        if (!Boolean(errorResource)) {
          this.ErrorMessage =
            ResourcesPaymentList.render.ErrorResources.InternalError;
        } else {
          this.ErrorMessage = errorResource.text;
        }
      });
      this.props.onDialogLoading(false);
      this.setState({
        isOpenErrorDialog: true,
      });
      return;
    }
  };

  handleDialogActions = (confirmation) => {
    const { classes } = this.props;
    return confirmation ? (
      [
        <div className={classes.flexContainerAction}>
          <LinkA
            className={classes.linkAStyle}
            onClick={() => {
              this.setState({
                confirmation: false,
              });
            }}
          >
            {Resources.StatusChangeDialog.DialogActions.backButton}
          </LinkA>
          <div>
            <Button
              className={classes.buttonCancel}
              onClick={() => {
                this.setState({
                  isOpenCancelDialogConfirm: true,
                });
              }}
            >
              {Resources.StatusChangeDialog.DialogActions.cancelButton}
            </Button>
            <Button
              className={classes.buttonOk}
              onClick={this.handleClickButtonOK}
            >
              {ResourcesPayment.MedicalFeeDialog.ButtonOk}
            </Button>
          </div>
        </div>,
      ]
    ) : (
      <React.Fragment>
        <Button
          className={classes.buttonCancel}
          onClick={() => {
            this.setState({
              isOpenCancelDialogConfirm: true,
            });
          }}
        >
          {Resources.StatusChangeDialog.DialogActions.cancelButton}
        </Button>
        {this.state.enableSubmitButton ? (
          <Button
            className={classes.buttonOk}
            onClick={this.validateAndSubmitForm(this.props.appointment)}
          >
            {Resources.StatusChangeDialog.DialogActions.confirmButton}
          </Button>
        ) : (
          <Button className={classes.buttonDisable}>
            {Resources.StatusChangeDialog.DialogActions.confirmButton}
          </Button>
        )}
      </React.Fragment>
    );
  };

  patientInformation = (patient) => {
    const { classes } = this.props;
    return [
      <Grid container wrap="nowrap" className={classes.gridContentPatient}>
        <Grid item className={classes.gridAvatar}>
          <img src={AccountImage} alt={""} />
        </Grid>
        <Grid item xs className={classes.textName}>
          <Typography className={classes.textGiveName}>
            {patient.familyName} {patient.givenName}
          </Typography>
          <Typography className={classes.textGiveNameKana}>
            {patient.familyNameKana} {patient.givenNameKana}
          </Typography>
        </Grid>
      </Grid>,
      <div className={classes.containerTable}>
        <table className={classes.tableStyle}>
          <thead>
            <tr>
              <th className={classes.thStyle + " " + classes.thBirthDay}>
                {Resources.StatusChangeDialog.DialogContent.table.thBirthDay}
              </th>
              <th className={classes.thStyle + " " + classes.thAge}>
                {Resources.StatusChangeDialog.DialogContent.table.thAge}
              </th>
              <th className={classes.thStyle + " " + classes.thGender}>
                {Resources.StatusChangeDialog.DialogContent.table.thGender}
              </th>
              <th className={classes.thStyle + " " + classes.thPhonenumber}>
                {Resources.StatusChangeDialog.DialogContent.table.thPhonenumber}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={classes.trContent}>
              <td className={classes.tdStyle}>
                {format(new Date(patient.birthdate), "yyyy/MM/dd")}
              </td>
              <td className={classes.tdStyle}>
                {patient.birthdate ? getAge(patient.birthdate) : " "}
              </td>
              <td
                className={
                  patient.gender && patient.gender === maleGender
                    ? classes.tdStyleGenderMale
                    : classes.tdStyleGenderFemale
                }
              >
                {patient.gender}
              </td>
              <td className={classes.tdStyle}>
                <NumberFormat
                  value={patient.phoneNumber}
                  displayType={"text"}
                  format="###-####-####"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>,
    ];
  };

  // render
  // --------------------------------------------------
  render() {
    const { appointment, classes, open } = this.props;
    const { confirmation } = this.state;
    const { patient, requireDrugName, requireDrugQuantity, menu, doctor } =
      appointment;

    let medicalExpense = "";
    if (appointment.payment && appointment.payment.medicalExpense) {
      medicalExpense =
        appointment.payment.medicalExpense
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        Resources.StatusChangeDialog.DialogContent.containerBorder
          .medicalFeeUnit;
    }
    const isBillingEnterprise =
      appointment.menu &&
      appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;

    const isSelfFundedAppointment = appointment.contractPlan
      ? isNumber(appointment.contractPlan.selfFundedAmount)
        ? true
        : false
      : false;
    const isMedicationInstructionOnline =
      menu?.medicalMethod === MEDICAL_METHOD.ONLINE &&
      menu?.medicalMenu === MEDICAL_MENU.MEDICATION_INSTRUCTION;

    return (
      <React.Fragment>
        <DialogMedical
          open={open}
          handleClickCancel={() => {
            this.setState({
              isOpenMedicalFee: false,
            });
          }}
          dialogActions={this.handleDialogActions(confirmation)}
          title={
            isBillingEnterprise
              ? ResourcesPayment.MedicalFeeDialog.DialogBillingEnterpriseTitle
              : ResourcesPayment.MedicalFeeDialog.DialogTitle
          }
        >
          {patient && this.patientInformation(patient)}
          {confirmation ? (
            <React.Fragment>
              <p className={classes.textQuestion}>
                {ResourcesPayment.MedicalFeeDialog.QuestionConfirm}
              </p>
              {isSelfFundedAppointment && (
                <div className={classes.selfFundedNote}>
                  {ResourcesPayment.MedicalFeeDialog.selfFundedNote}
                </div>
              )}
              <div className={classes.containerBorderConfirm}>
                <div className={classes.container}>
                  <div className={classes.titleItem}>
                    {
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .undertaker
                    }
                  </div>
                  <div className={classes.contentItem}>
                    {menu && menu.medicalMethod === MEDICAL_METHOD.OFFLINE
                      ? Resources.StatusChangeDialog.DialogContent
                          .containerBorder.offline
                      : `${doctor.familyName} ${doctor.givenName}`}
                  </div>
                </div>
                {!isBillingEnterprise && (
                  <React.Fragment>
                    <div className={classes.container}>
                      <div className={classes.titleItem}>
                        {
                          Resources.StatusChangeDialog.DialogContent
                            .containerBorder.medicalFee
                        }
                      </div>
                      <div className={classes.contentItem}>
                        <span>{medicalExpense}</span>
                        <span className={classes.textMedicalFeeNote}>
                          {isMedicationInstructionOnline
                            ? ResourcesPayment.MedicalFeeDialog
                                .MedicalFeeNoteConfirm.medicationInstruction
                            : ResourcesPayment.MedicalFeeDialog
                                .MedicalFeeNoteConfirm.normalMenu}
                        </span>
                      </div>
                    </div>
                    {requireDrugName && (
                      <div className={classes.container}>
                        <div className={classes.titleItem}>
                          {
                            Resources.StatusChangeDialog.DialogContent
                              .containerBorder.drugName
                          }
                        </div>
                        <div className={classes.contentItem}>
                          {appointment.drugsName &&
                            appointment.drugsName.trim()}
                        </div>
                      </div>
                    )}
                    {requireDrugQuantity && (
                      <div className={classes.container}>
                        <div className={classes.titleItem}>
                          {
                            Resources.StatusChangeDialog.DialogContent
                              .containerBorder.drugQuantity
                          }
                        </div>
                        <div className={classes.contentItem}>
                          {appointment.drugsQuantity &&
                            appointment.drugsQuantity.trim()}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
                {isBillingEnterprise && (
                  <React.Fragment>
                    <div className={classes.container}>
                      <div className={classes.titleItem}>
                        {
                          Resources.StatusChangeDialog.DialogContent
                            .containerBorder.billingCategory
                        }
                      </div>
                      <div className={classes.contentItem}>
                        {
                          Resources.StatusChangeDialog.DialogContent
                            .containerBorder.billingCategoryOption[
                            appointment.billingCategory
                          ]
                        }
                      </div>
                    </div>
                    <div className={classes.container}>
                      <div className={classes.titleItem}>
                        {
                          Resources.StatusChangeDialog.DialogContent
                            .containerBorder.drugNameEWP
                        }
                      </div>
                      <div className={classes.contentItem}>
                        {appointment.drugsName && appointment.drugsName.trim()}
                      </div>
                    </div>
                    <div className={classes.container}>
                      <div className={classes.titleItem}>
                        {
                          Resources.StatusChangeDialog.DialogContent
                            .containerBorder.drugQuantity
                        }
                      </div>
                      <div className={classes.contentItem}>
                        {appointment.drugsQuantity &&
                          appointment.drugsQuantity.trim()}
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className={classes.container}>
                  <div className={classes.titleItem}>
                    {
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .memo
                    }
                  </div>
                  <div className={classes.contentItem}>
                    {appointment.memo && appointment.memo.trim()}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className={classes.containerBorder}>
              <div className={classes.container}>
                <div className={classes.titleItem}>
                  {
                    Resources.StatusChangeDialog.DialogContent.containerBorder
                      .undertaker
                  }
                </div>
                <div className={classes.contentItem}>
                  {menu && menu.medicalMethod === MEDICAL_METHOD.OFFLINE
                    ? Resources.StatusChangeDialog.DialogContent.containerBorder
                        .offline
                    : `${doctor.familyName} ${doctor.givenName}`}
                </div>
              </div>
              {!isBillingEnterprise && (
                <React.Fragment>
                  <MedicalFeeInput
                    title={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .medicalFee
                    }
                    isValidateError={
                      !(
                        this.state.checkMedicalFeeZero &&
                        this.state.checkMedicalFeeLength
                      )
                    }
                    errMessage={
                      !this.state.checkMedicalFeeLength
                        ? Resources.StatusChangeDialog.DialogContent
                            .containerBorder.medicalFeeLengthErrorMessage
                        : Resources.StatusChangeDialog.DialogContent
                            .containerBorder.medicalFeeZeroErrorMessage
                    }
                    handleChangeMedicalFee={(e) =>
                      this.props.handleChangeMedicalFee(
                        e,
                        this.handleEnableSubmitButton
                      )
                    }
                    handleBlurMedicalFee={this.props.handleBlurMedicalFee}
                    medicalFeeValue={appointment.payment.medicalExpense}
                    hasErrorClass={
                      !(
                        this.state.checkMedicalFeeZero &&
                        this.state.checkMedicalFeeLength
                      )
                        ? classes.hasError
                        : classes.inputContent
                    }
                    inputFeePlaceholder={0}
                    medicalFeeUnit={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .medicalFeeUnit
                    }
                    medicalFeeNote={
                      isMedicationInstructionOnline
                        ? ResourcesPayment.MedicalFeeDialog.MedicalFeeNoteInput
                            .medicationInstruction
                        : ResourcesPayment.MedicalFeeDialog.MedicalFeeNoteInput
                            .normalMenu
                    }
                  />
                  {requireDrugName && (
                    <DrugInput
                      title={
                        Resources.StatusChangeDialog.DialogContent
                          .containerBorder.drugName
                      }
                      isValidateError={
                        !(
                          this.state.checkDrugNameLength &&
                          this.state.checkDrugNameEmpty
                        )
                      }
                      errMessage={
                        !this.state.checkDrugNameLength
                          ? Resources.StatusChangeDialog.DialogContent
                              .containerBorder.drugNameMaxLengthErrorMessage
                          : Resources.StatusChangeDialog.DialogContent
                              .containerBorder.drugNameEmptyErrorMessage
                      }
                      drugInputValue={appointment.drugsName}
                      drugInputPlaceholder={
                        ResourcesPayment.MedicalFeeDialog.PlaceHolderDrugsName
                      }
                      handleChangeDrugInput={(e) =>
                        this.props.handleChangeDrugName(
                          e,
                          this.handleEnableSubmitButton
                        )
                      }
                      hasErrorClass={
                        !(
                          this.state.checkDrugNameLength &&
                          this.state.checkDrugNameEmpty
                        )
                          ? classes.hasError
                          : classes.inputContent
                      }
                    />
                  )}
                  {requireDrugQuantity && (
                    <DrugInput
                      title={
                        Resources.StatusChangeDialog.DialogContent
                          .containerBorder.drugQuantity
                      }
                      isValidateError={
                        !(
                          this.state.checkDrugQuantityLength &&
                          this.state.checkDrugQuantityEmpty
                        )
                      }
                      errMessage={
                        !this.state.checkDrugQuantityLength
                          ? Resources.StatusChangeDialog.DialogContent
                              .containerBorder.drugQuantityMaxLengthErrorMessage
                          : Resources.StatusChangeDialog.DialogContent
                              .containerBorder.drugQuantityEmptyErrorMessage
                      }
                      drugInputValue={appointment.drugsQuantity}
                      hasErrorClass={
                        !(
                          this.state.checkDrugQuantityLength &&
                          this.state.checkDrugQuantityEmpty
                        )
                          ? classes.hasError
                          : classes.inputContent
                      }
                      drugInputPlaceholder={
                        ResourcesPayment.MedicalFeeDialog
                          .PlaceHolderDrugsQuantity
                      }
                      handleChangeDrugInput={(e) =>
                        this.props.handleChangeDrugQuantity(
                          e,
                          this.handleEnableSubmitButton
                        )
                      }
                    />
                  )}
                  <MemoTextarea
                    title={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .memo
                    }
                    checkMemoInput={this.state.checkMemoInput}
                    memoErrMessage={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .memoErrMessage
                    }
                    memoValue={appointment.memo}
                    placeholder={
                      ResourcesPayment.MedicalFeeDialog.PlaceHolderMemo
                    }
                    handleChangeMemo={(e) =>
                      this.props.handleChangeMemo(
                        e,
                        this.handleEnableSubmitButton
                      )
                    }
                    hasErrorClass={
                      !this.state.checkMemoInput
                        ? classes.hasError
                        : classes.textAreaMemo
                    }
                  />
                </React.Fragment>
              )}
              {isBillingEnterprise && (
                <React.Fragment>
                  <BillingCategorySelect
                    title={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .billingCategory
                    }
                    billingCategoryValue={appointment.billingCategory}
                    handleChangeBillingCategory={(e) =>
                      this.props.handleChangeBillingCategory(
                        e,
                        this.handleEnableSubmitButton
                      )
                    }
                  />
                  <DrugNameInput
                    title={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .drugNameEWP
                    }
                    isValidateError={
                      !(
                        this.state.checkDrugNameLength &&
                        this.state.checkDrugNameEmpty
                      )
                    }
                    errMessage={
                      !this.state.checkDrugNameEmpty
                        ? Resources.StatusChangeDialog.DialogContent
                            .containerBorder.drugNameEmptyErrorMessageEWP
                        : Resources.StatusChangeDialog.DialogContent
                            .containerBorder.drugNameMaxLengthErrorMessageEWP
                    }
                    drugsNameValue={appointment.drugsName}
                    inputMethod={
                      appointment.contractPlan.plan.prescriptionDrug.inputMethod
                    }
                    selectableDrugNames={
                      appointment.contractPlan.plan.prescriptionDrug
                        .selectableDrugNames
                    }
                    disabled={this.state.isDrugNameInputDisabled}
                    handleChangeDrugName={(e) =>
                      this.props.handleChangeDrugName(
                        e,
                        this.handleEnableSubmitButton
                      )
                    }
                  />
                  <DrugQuantitySelect
                    title={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .drugQuantity
                    }
                    drugsQuantityValue={appointment.drugsQuantity}
                    handleChangeDrugQuantity={(e) =>
                      this.props.handleChangeDrugQuantity(
                        e,
                        this.handleEnableSubmitButton
                      )
                    }
                    disabled={this.state.isDrugQuantitySelectDisabled}
                    isValidateError={!this.state.checkDrugQuantityValid}
                    errMessage={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .drugQuantityValidErrorMessageEWP
                    }
                  />
                  <MemoTextarea
                    title={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .memo
                    }
                    checkMemoInput={this.state.checkMemoInput}
                    memoErrMessage={
                      Resources.StatusChangeDialog.DialogContent.containerBorder
                        .memoErrMessage
                    }
                    memoValue={appointment.memo}
                    placeholder={
                      ResourcesPayment.MedicalFeeDialog
                        .BillingEnterprisePlaceHolderMemo
                    }
                    handleChangeMemo={(e) =>
                      this.props.handleChangeMemo(
                        e,
                        this.handleEnableSubmitButton
                      )
                    }
                    hasErrorClass={
                      !this.state.checkMemoInput
                        ? classes.hasError
                        : classes.textAreaMemo
                    }
                  />
                </React.Fragment>
              )}
            </div>
          )}
        </DialogMedical>
        <DialogCancelConfirm
          open={this.state.isOpenCancelDialogConfirm}
          handleCancelDialogConfirm={this.handleCancelDialogConfirm}
          handleCloseDialogConfirm={this.props.handleCloseMedicalFeeDialog}
          title={Resources.AppointmentList.DialogConfirm.title}
        >
          {Resources.AppointmentList.DialogConfirm.content}
        </DialogCancelConfirm>
        {this.state.isOpenErrorDialog && (
          <DialogError
            open={this.state.isOpenErrorDialog}
            handleCloseDialogError={() => {
              window.location.reload();
            }}
            children={(this.ErrorMessage ? this.ErrorMessage : " ")
              .split("\n")
              .map((item, index) => {
                return (
                  <span key={index}>
                    {index > 0 ? <br /> : ""}
                    {item}
                  </span>
                );
              })}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MedicalFeeDialog);
