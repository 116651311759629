import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { startOfMonth, endOfMonth, parseISO } from "date-fns";
import styled from "styled-components";
import { japaneseList } from "../../../Resources/japaneseList";
import HospitalPaymentConfirm from "../../organisms/payment/HospitalPaymentConfirm";
import HospitalPaymentRow from "./HospitalPaymentRow";
import {
  client,
  GetAppointment,
  ListAppointments,
  RequestDeliveryProcess,
  ResetDeliveryProcess,
  UpdateAppointmentOnline,
} from "../../../graphql";
import { downloadPaymentCSV } from "../../../Utils/Csv";
import MedicalFeeDialog from "./MedicalFeeDialog";
import PaymentDialog from "./PaymentDialog";
import DeliveryProcessDialog from "./deliveryProcess/DeliveryProcessDialog";
import DeliveryProcessInputDialog from "./deliveryProcess/DeliveryProcessInputDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogConfirm from "../../molecules/dialogs/DialogConfirm";
import DialogError from "../../molecules/dialogs/DialogError";
import YamatoInquiryAnnounce from "./deliveryProcess/YamatoInquiryAnnounce";
import MonthSelectOperator from "./monthSelect/MonthSelectOperator";
import PaginateOperator from "./PaginateOperator";
import LoadingScreen from "../../molecules/others/LoadingScreen";
import * as Sentry from "@sentry/browser";

import {
  DELIVERY_OPTION,
  SETTLEMENT_ERROR,
  PAYMENT_METHOD,
  MEDICAL_METHOD,
  FACILITY_TYPE,
} from "../../../Utils/const";

import HospitalPaymentRowDoctor from "../../molecules/payment/HospitalPaymentRowDoctor";
import HospitalPaymentRowDatetime from "../../molecules/payment/HospitalPaymentRowDatetime";
import HospitalPaymentRowName from "../../molecules/payment/HospitalPaymentRowName";
import { patientDetail } from "../../../lib/window/PatientDetail";
import DialogMedicineDelivery from "../../molecules/dialogs/DialogMedicineDelivery";

const Resource = japaneseList.Components.Payment.HospitalPaymentList;
const { ErrorResources } = Resource.render;
const DeliveryProductName = japaneseList.models.appointment.deliveryProductName;
const tableWillScrollWidth = 923;

const styles = (theme) => ({
  root: {
    overflowX: "auto",
    fontSize: "12px",
    height: "calc(100vh - 250px)",
  },
  fixedTable: {
    width: "510px",
    float: "left",
  },
  fixedTableContainer: {
    width: "100%",
  },
  scrollTable: {
    position: "relative",
    float: "left",
    width: "calc(100% - 510px)",
  },
  scrollTableContainer: {
    float: "left",
    width: "100%",
    overflow: "auto",
  },
  head: {
    backgroundColor: "#999999",
    color: "#FFFFFF",
    textAlign: "center",
    whiteSpace: "nowrap",
    paddingRight: 0,
    fontSize: "12px",
    fontWeight: "bold",
    "& tr": {
      height: "42px",
    },
  },
  tbl: {
    fontSize: "14px",
    width: "100%",
    tableLayout: "auto",
    borderCollapse: "collapse",
    "& tbody tr:nth-child(odd)": {
      backgroundColor: "#fff",
      height: "87px",
    },
    "& tbody tr:nth-child(even)": {
      backgroundColor: "#FAFAFA",
      height: "87px",
    },
    borderLeft: "1px solid #CCCCCC",
    borderBottom: "1px solid #CCCCCC",
  },
  doctor: {
    width: "150px",
    minWidth: "150px",
    padding: "0",
    wordBreak: "none",
    whiteSpace: "nowrap",
  },
  noHead: {
    borderLeft: `1px solid ${theme.color.tableHead}`,
  },
  dateTime: {
    minWidth: "100px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  name: {
    minWidth: "80px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  menu: {
    minWidth: "80px",
  },
  reciveMedicaine: {
    width: "80px",
  },
  deliveryOpt: {
    width: "180px",
    minWidth: "180px",
  },
  deliveryProc: {
    width: "92px",
    minWidth: "92px",
    whiteSpace: "nowrap",
  },
  deliveryFee: {
    width: "70px",
    minWidth: "70px",
    whiteSpace: "nowrap",
  },
  deliveryArrangementFee: {
    width: "70px",
    minWidth: "70px",
    whiteSpace: "nowrap",
  },
  treatmentFee: {
    width: "102px",
    minWidth: "102px",
    whiteSpace: "nowrap",
  },
  totalFee: {
    width: "60px",
    whiteSpace: "nowrap",
  },
  status: {
    width: "100px",
    minWidth: "100px",
    whiteSpace: "nowrap",
    borderRight: "1px solid #CCCCCC",
  },
  headNote: {
    fontSize: "10px",
    fontWeight: "normal",
  },
  divNoData: {
    fontSize: "14px",
    borderLeft: "1px solid #CCCCCC",
    borderRight: "1px solid #CCCCCC",
    borderBottom: "1px solid #CCCCCC",
    paddingLeft: "5px",
  },
  operatorWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const TblHead = styled.td`
  position: sticky;
  z-index: 1;
  border-left: 1px solid #cccccc;
`;

const Division = styled.div`
  position: absolute;
  left: 0px;
  margin-top: 42px;
  width: 6px;
  display: inline-block;
  background-image: linear-gradient(to right, #cccccc, #ffffff);
  z-index: 2;
`;

class HospitalPaymentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenHospitalPaymentConfirm: false,
      isOpenMedicalFee: false,
      isOpenPaymentDialog: false,
      isOpenDeliveryInputDialog: false,
      isOpenDeliveryConfirmDialog: false,
      isOpenDeliveryResetDialog: false,
      isOpenDeliveryRusultDialog: false,
      isOpenCancelDialogConfirm: false,
      isOpenDeliveryCompleteDialog: false,
      isOpenErrorDialog: false,
      isOpenMonthSelectDialog: false,
      appointments: [],
      appointmentSelect: {},
      appointmentsTotalCountInMonth: 0,
      appointmentsOffset: 0,
      deliverySelectedOption: null,
      errorMessage: null,
      errorTitle: null,
      isDeliveryDateError: false,
      isAfterDeliveryError: false,
      isLoading: false,
      requestDelivery: {
        name: "",
        pickUpDate: "",
        pickUpTimeSpan: "06",
        comment: "",
        deliveryFee: "",
      },
      selectedDate: new Date(),
      minimumFrom: new Date(),
      maximumFrom: new Date(),
      settleErrorResource: null,
      isOpenMedicineDeliveryDialog: false,
    };
    this.container = null;
  }

  async componentDidMount() {
    await this.loadAppointmentList(true);
    window.addEventListener("resize", this.handleResizeWindow);
  }

  // TODO: 暫定でもってきたので、Hooksにして消す
  FILTER_STATUS = [
    "examCompleted",
    "paymentCompleted",
    "canceledPatientFromToday",
  ];
  MEDICAL_METHOD_FILTERS = ["online", "offline"];
  APPOINTMENT_ORDERBY = ["from_desc", "from_asc"];
  APPOINTMENTS_ITEM_LIMIT = 20;

  /**
   * TODO: appointments及び更新処理をpagesに依存させる
   */
  loadAppointmentList = async (isFirst = false) => {
    try {
      if (!this.state.isLoading) {
        this.props.setIsLoading(true);
      }
      const { data, errors } = await client.query({
        query: ListAppointments,
        variables: {
          status: this.FILTER_STATUS,
          orderBy: this.APPOINTMENT_ORDERBY[0],
          medicalMethodFilters: this.MEDICAL_METHOD_FILTERS,
          requestPage: "payment",
          start: startOfMonth(this.state.selectedDate),
          end: endOfMonth(this.state.selectedDate),
          limit: this.APPOINTMENTS_ITEM_LIMIT,
          offset: this.state.appointmentsOffset,
          requireFromRange: isFirst,
        },
      });
      if (!errors) {
        this.setState({
          appointments: data.appointments.items,
          appointmentsTotalCountInMonth: data.appointments.totalItemCount,
        });
        if (isFirst) {
          this.setState({
            minimumFrom: parseISO(data.appointments.minimumFrom),
            maximumFrom: parseISO(data.appointments.maximumFrom),
          });
        }
      } else {
        console.log("ListAppointments errors:" + JSON.stringify(errors));
        Sentry.captureException(
          new Error(
            "ListAppointments errorType:" +
              (errors.length ? errors[0].errorType : "")
          )
        );
      }
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    } finally {
      this.props.setAppointments(this.state.appointments);
      this.props.setIsLoading(false);
    }
  };

  loadAppointmentAndUpdateState = async (appo, updateState) => {
    const variables =
      appo.menu && appo.menu.medicalMethod === MEDICAL_METHOD.OFFLINE
        ? {
            patientId: appo.patient.patientId,
            createTime: appo.createTime,
          }
        : {
            patientId: appo.patient.patientId,
            createTime: appo.createTime,
            medicalDoctorId: appo.doctor.doctorId,
          };
    client
      .query({
        query: GetAppointment,
        variables: variables,
      })
      .then((res) => {
        if (res.data && res.data.appointment) {
          // TODO: there is not exists "address" in data.appointment
          const patientOrigin = appo.patient;
          const patient = res.data.appointment.patient;
          if (patient && patientOrigin && patientOrigin.address) {
            this.setState({
              appointmentSelect: {
                ...appo,
                ...res.data.appointment,
                patient: {
                  ...res.data.appointment.patient,
                  address: patientOrigin.address,
                },
                // TODO: there is not exists "deliveryTotalFee,deliverySelectedOption" in data.appointment.payment
                payment: {
                  ...res.data.appointment.payment,
                  deliveryTotalFee: appo.payment.deliveryTotalFee,
                  deliverySelectedOption: appo.payment.deliverySelectedOption,
                },
                doctor: appo.doctor,
              },
              ...updateState,
            });
          } else {
            this.setState({
              appointmentSelect: {
                ...res.data.appointment,
                doctor: appo.doctor,
              },
              ...updateState,
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          errorMessage: ErrorResources.InternalError,
          isOpenErrorDialog: true,
        });
      });
  };

  handleChangeOffset = (offset) => {
    this.setState({ appointmentsOffset: offset }, this.loadAppointmentList);
  };

  handleOpenMonthSelectDialog = () => {
    this.setState({ isOpenMonthSelectDialog: true });
  };

  handleChangeDate = async (date) => {
    this.setState(
      {
        selectedDate: date,
        appointmentsOffset: 0,
        isOpenMonthSelectDialog: false,
      },
      this.loadAppointmentList
    );
  };

  handleCloseMonthSelectDialog = () => {
    this.setState({ isOpenMonthSelectDialog: false });
  };

  handleOpenConfirmDialog = async (appo) => {
    await this.loadAppointmentAndUpdateState(appo, {
      isOpenHospitalPaymentConfirm: true,
    });
  };

  handleCloseConfirmDialog = () => {
    this.setState({ isOpenHospitalPaymentConfirm: false });
  };

  handleOpenMedicalFeeDialog = async (appo) => {
    await this.loadAppointmentAndUpdateState(appo, { isOpenMedicalFee: true });
  };

  handleOpenPaymentFeeDialog = async (appo) => {
    await this.loadAppointmentAndUpdateState(appo, {
      isOpenPaymentDialog: true,
    });
  };

  handleChangeMedicalFee = (e, handleEnableSubmitButton) => {
    const appointmentSelect = {
      ...this.state.appointmentSelect,
      payment: {
        ...this.state.appointmentSelect.payment,
        medicalExpense: e.target.value,
      },
    };
    this.setState({ appointmentSelect }, handleEnableSubmitButton);
  };

  handleBlurMedicalFee = (value) => {
    const appointmentSelect = {
      ...this.state.appointmentSelect,
      payment: {
        ...this.state.appointmentSelect.payment,
        medicalExpense: value,
      },
    };
    this.setState({
      appointmentSelect,
    });
  };

  handleChangeDrugName = (e, handleEnableSubmitButton) => {
    const appointmentSelect = {
      ...this.state.appointmentSelect,
      drugsName: e.target.value,
    };
    this.setState({ appointmentSelect }, handleEnableSubmitButton);
  };

  handleChangeDrugQuantity = (e, handleEnableSubmitButton) => {
    const appointmentSelect = {
      ...this.state.appointmentSelect,
      drugsQuantity: e.target.value,
    };
    this.setState({ appointmentSelect }, handleEnableSubmitButton);
  };

  handleChangeMemo = (e, handleEnableSubmitButton) => {
    const appointmentSelect = {
      ...this.state.appointmentSelect,
      memo: e.target.value,
    };
    this.setState({ appointmentSelect }, handleEnableSubmitButton);
  };

  handleChangeBillingCategory = (e, handleEnableSubmitButton) => {
    const appointmentSelect = {
      ...this.state.appointmentSelect,
      billingCategory: e.target.value,
    };
    this.setState({ appointmentSelect }, handleEnableSubmitButton);
  };

  handleSetDrugsInfo = (drugsInfo, handleEnableSubmitButton) => {
    const appointmentSelect = {
      ...this.state.appointmentSelect,
      ...drugsInfo,
    };
    this.setState({ appointmentSelect }, handleEnableSubmitButton);
  };

  handleCloseMedicalFeeDialog = () => {
    this.setState({ isOpenMedicalFee: false });
  };

  handleClosePaymentDialog = async () => {
    this.setState({
      isOpenPaymentDialog: false,
      settleErrorResource: null,
    });
  };

  resetRequestDeliveryState = () => {
    this.setState({
      requestDelivery: {
        name: "",
        pickUpDate: "",
        pickUpTimeSpan: "06",
        comment: "",
        deliveryFee: "",
      },
    });
  };

  updateRequestDeliveryState = (updateItem, callback = () => {}) => {
    this.setState(
      {
        requestDelivery: {
          ...this.state.requestDelivery,
          ...updateItem,
        },
      },
      callback
    );
  };

  handleOpenDeliveryInputDialog = (
    appointmentSelect,
    deliverySelectedOption
  ) => {
    this.resetRequestDeliveryState();
    this.setState({
      appointmentSelect,
      deliverySelectedOption,
      isOpenDeliveryInputDialog: true,
    });
  };

  handleOpenDeliveryConfirmDialog = () => {
    this.setState({
      isOpenDeliveryConfirmDialog: true,
      isOpenDeliveryInputDialog: false,
    });
  };

  handleBackDeliveryInputDialog = () => {
    this.setState({
      isOpenDeliveryInputDialog: true,
      isOpenDeliveryConfirmDialog: false,
    });
  };

  handleCloseDeliveryInputDialog = () => {
    this.setState({ isOpenDeliveryInputDialog: false });
  };

  handleOpenCancelDialog = () => {
    this.setState({ isOpenCancelDialogConfirm: true });
  };

  handleCancelDialogConfirm = () => {
    this.setState({ isOpenCancelDialogConfirm: false });
  };

  handleOpenDeliveryResetDialog = (appointmentSelect) => {
    this.setState({
      appointmentSelect,
      isOpenDeliveryResetDialog: true,
    });
  };

  handleCloseDialogConfirm = () => {
    this.resetRequestDeliveryState();
    this.setState({
      deliverySelectedOption: null,
      isOpenDeliveryInputDialog: false,
      isOpenDeliveryConfirmDialog: false,
      isOpenCancelDialogConfirm: false,
      isOpenDeliveryResetDialog: false,
      isOpenDeliveryCompleteDialog: false,
      isOpenMedicineDeliveryDialog: false,
    });
  };

  handleCloseDeliveryCompleteDialog = () => {
    this.setState({ isOpenDeliveryCompleteDialog: false });
    this.loadAppointmentList();
  };

  handleOpenDeliveryResultDialog = (appointmentSelect) => {
    this.setState({
      appointmentSelect,
      isOpenDeliveryRusultDialog: true,
    });
  };

  handleCloseMedicineDeliveryDialog = () => {
    this.setState({
      isOpenMedicineDeliveryDialog: false,
    });
  };

  handleOpenMedicineDeliveryDialog = (appointmentSelect) => {
    this.setState({
      appointmentSelect,
      isOpenMedicineDeliveryDialog: true,
    });
  };

  handleCloseDeliveryRusultDialog = () => {
    this.setState({ isOpenDeliveryRusultDialog: false });
  };

  handleCsvDownload = () => {
    const { payment } = this.state.appointmentSelect || {};
    if (payment.deliverySelectedOption === DELIVERY_OPTION.csvPost) {
      downloadPaymentCSV(this.state.appointmentSelect);
    }
  };

  requestDeliveryProcess = async () => {
    this.setState({ isLoading: true });

    try {
      const appointment = this.state.appointmentSelect;
      const payload = {
        patientId: appointment.patient.patientId,
        createTime: appointment.createTime,
        deliveryProductName: this.state.requestDelivery.name,
        deliveryFee: parseInt(this.state.requestDelivery.deliveryFee, 10),
        pickUpDate: this.state.requestDelivery.pickUpDate,
        pickUpTimeSpan: this.state.requestDelivery.pickUpTimeSpan,
        deliverySelectedOption: this.state.deliverySelectedOption,
        comment: this.state.requestDelivery.comment,
        medicalDoctorId: appointment.doctor.doctorId,
      };
      const { errors } = await client.mutate({
        mutation: RequestDeliveryProcess,
        variables: payload,
      });

      if (!errors) {
        this.setState({ isOpenDeliveryConfirmDialog: false });
        await this.loadAppointmentList();

        if (this.state.deliverySelectedOption === DELIVERY_OPTION.csvPost) {
          const deliveryProductName =
            DeliveryProductName[this.state.requestDelivery.name] || "";
          downloadPaymentCSV(appointment, { deliveryProductName });
        }
      } else {
        if (
          this.state.deliverySelectedOption === DELIVERY_OPTION.yamato &&
          (errors[0].errorType === "E104" || errors[0].errorType === "E105")
        ) {
          this.handleBackDeliveryInputDialog();
        } else {
          this.setErrorMessage(errors);
          this.setErrorTitle(errors);
        }
      }
    } catch (e) {
      this.setErrorMessage(null);
      this.setErrorTitle(null);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  resetDeliveryProcess = async () => {
    this.setState({ isLoading: true });

    try {
      const appointment = this.state.appointmentSelect;
      const payload = {
        patientId: appointment.patient.patientId,
        createTime: appointment.createTime,
        medicalDoctorId: appointment.doctor.doctorId,
      };
      const { errors } = await client.mutate({
        mutation: ResetDeliveryProcess,
        variables: payload,
      });

      if (!errors) {
        await this.loadAppointmentList();
        this.setState({
          isOpenDeliveryResetDialog: false,
          deliverySelectedOption: null,
        });
      } else {
        this.setErrorMessage(errors);
      }
    } catch (e) {
      this.setErrorMessage(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  requestPayment = async ({
    medicalExpense,
    billingCategory,
    drugsName,
    drugsQuantity,
    memo,
  }) => {
    this.setState({ isLoading: true });

    try {
      const appointment = this.state.appointmentSelect;
      const isBillingEnterprise =
        appointment.menu &&
        appointment.menu.paymentMethod === PAYMENT_METHOD.BILLING_ENTERPRISE;
      const variables = {
        patientId: appointment.patient.patientId,
        createTime: appointment.createTime,
        memo,
      };
      const { data, errors } = await client.mutate({
        mutation: UpdateAppointmentOnline,
        variables: isBillingEnterprise
          ? {
              ...variables,
              drugsQuantity,
              drugsName,
              billingCategory,
              medicalExpense: 0,
              medicalDoctorId: appointment.doctor.doctorId,
            }
          : {
              ...variables,
              medicalExpense,
              ...(appointment.requireDrugName ? { drugsName } : {}),
              ...(appointment.requireDrugQuantity ? { drugsQuantity } : {}),
              medicalDoctorId: appointment.doctor.doctorId,
            },
      });
      if (errors) {
        const errorResource = this.getErrorResource(errors);
        this.setState({
          settleErrorResource: errorResource,
        });
      } else if (data) {
        if (this.state.deliverySelectedOption === DELIVERY_OPTION.yamato) {
          await this.loadAppointmentAndUpdateState(appointment, {
            isOpenDeliveryCompleteDialog: true,
          });
        } else {
          await this.loadAppointmentList();
        }
        this.handleClosePaymentDialog();
      }
    } catch (e) {
      console.log("errors", e);
      this.setErrorMessage(e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getErrorResource = (error) => {
    const _getResource = (code) =>
      ErrorResources.Errors.find((item) => item.code === code);
    if (error instanceof Error) return _getResource("E06");
    return error.reduce((mess, e) => mess || _getResource(e.errorType), null);
  };

  getErrorTitle = (error) => {
    const _getResource = (code) =>
      ErrorResources.Errors.find((item) => item.code === code);
    const settlementErrorSet = [
      SETTLEMENT_ERROR.DELIVERY_MAINTENANCE,
      SETTLEMENT_ERROR.DELIVERY_PATIENT,
      SETTLEMENT_ERROR.CREDIT_CARD,
      SETTLEMENT_ERROR.CARD_SYSTEM,
      SETTLEMENT_ERROR.UNEXPECTED_SYSTEM,
      SETTLEMENT_ERROR.DELIVERY_SYSTEM,
      SETTLEMENT_ERROR.FAILED_CANCEL,
      SETTLEMENT_ERROR.INVALID_AMOUNT,
    ];
    const errorType = error.find((e) =>
      settlementErrorSet.includes(e.errorType)
    );
    const errorResource = _getResource(errorType);
    return errorResource;
  };

  setErrorMessage = (error) => {
    const errorResource = this.getErrorResource(error);
    const errorMessage = errorResource
      ? errorResource.text
      : Resource.render.ErrorResources.InternalError;

    this.setState({
      errorMessage,
      isLoading: false,
      isOpenErrorDialog: true,
      isDeliveryDateError:
        errorResource && errorResource.code === SETTLEMENT_ERROR.DELIVERY_PAST,
      isAfterDeliveryError:
        errorResource && errorResource.code === SETTLEMENT_ERROR.DELIVERY_OLD,
    });
  };

  setErrorTitle = (error) => {
    const errorResource = this.getErrorTitle(error);
    const errorTitle = errorResource
      ? errorResource.title
      : Resource.render.ErrorResources.InternalErrorTitle;

    this.setState({
      errorTitle,
    });
  };

  handleCloseDialogError = async () => {
    const { isDeliveryDateError, appointmentSelect } = this.state;
    if (isDeliveryDateError) {
      // if yamato request date is past, open input dialog again
      await this.loadAppointmentAndUpdateState(appointmentSelect, {
        isDeliveryDateError: false,
        isOpenErrorDialog: false,
        isOpenDeliveryInputDialog: true,
        deliverySelectedOption: DELIVERY_OPTION.yamato,
      });
    } else {
      window.location.reload();
    }
  };

  handleResizeWindow = (e) => {
    this.setState({
      showDivision:
        window.innerWidth - this.scrollTableContainer.offsetLeft <
        tableWillScrollWidth,
    });
  };

  setDialogLoading = (isDialogLoading) => {
    this.setState({
      isLoading: isDialogLoading,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResizeWindow);
  }

  render() {
    const { classes, facilityType, isCentralClinic } = this.props;
    const {
      appointments,
      appointmentsTotalCountInMonth,
      appointmentsOffset,
      appointmentSelect,
      selectedDate,
      minimumFrom,
      maximumFrom,
      deliverySelectedOption,
      requestDelivery,
      errorMessage,
      isAfterDeliveryError,
      pickUpDateCandidates,
      isOpenHospitalPaymentConfirm,
      isOpenMedicalFee,
      isOpenPaymentDialog,
      isOpenErrorDialog,
      isOpenCancelDialogConfirm,
      isOpenDeliveryInputDialog,
      isOpenDeliveryCompleteDialog,
      isOpenDeliveryResetDialog,
      isOpenDeliveryRusultDialog,
      isOpenDeliveryConfirmDialog,
      isOpenMedicineDeliveryDialog,
      isLoading,
      settleErrorResource,
      showDivision,
    } = this.state;
    const theadResource = Resource.render.div.table.thead;
    return (
      <React.Fragment>
        <div className={classes.operatorWrapper}>
          <MonthSelectOperator
            handleChangeDate={this.handleChangeDate}
            selectedDate={selectedDate}
            minimumDate={minimumFrom}
            maximumDate={maximumFrom}
          />
          <PaginateOperator
            offset={appointmentsOffset}
            totalItemCount={appointmentsTotalCountInMonth}
            limit={this.APPOINTMENTS_ITEM_LIMIT}
            handleChangeOffset={this.handleChangeOffset}
          />
        </div>
        <div className={classes.root}>
          <div className={classes.fixedTable}>
            <div className={classes.fixedTableContainer}>
              <table className={classes.tbl}>
                <thead className={classes.head}>
                  <tr>
                    <TblHead className={`${classes.doctor} ${classes.noHead}`}>
                      {theadResource.undertaker}
                    </TblHead>
                    <TblHead className={classes.dateTime}>
                      {theadResource.dateTime}
                    </TblHead>
                    <TblHead className={classes.name}>
                      {theadResource.name}
                    </TblHead>
                  </tr>
                </thead>
                {appointments.length > 0 && (
                  <tbody>
                    {appointments.map((appo, i) => (
                      <tr key={`appointment-${i}`}>
                        <HospitalPaymentRowDoctor appo={appo} />
                        <HospitalPaymentRowDatetime appo={appo} />
                        <HospitalPaymentRowName
                          appo={appo}
                          openPatientDetailWindow={
                            patientDetail.openPatientDetailWindow
                          }
                        />
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
          <div
            className={classes.scrollTable}
            ref={(c) => (this.scrollTableContainer = c)}
          >
            {showDivision && (
              <Division style={{ height: `${appointments.length * 87}px` }} />
            )}
            <div className={classes.scrollTableContainer}>
              <table className={classes.tbl}>
                <thead className={classes.head}>
                  <tr>
                    <TblHead className={classes.menu}>
                      {theadResource.menu}
                    </TblHead>
                    {facilityType &&
                      facilityType === FACILITY_TYPE.HOSPITAL && (
                        <TblHead className={classes.reciveMedicaine}>
                          {theadResource.reciveMedicaine}
                        </TblHead>
                      )}
                    <TblHead className={classes.deliveryOpt}>
                      {theadResource.deliveryOpt}
                    </TblHead>
                    <TblHead className={classes.deliveryProc}>
                      {theadResource.deliveryProc}
                    </TblHead>
                    <TblHead className={classes.deliveryFee}>
                      <div>{theadResource.deliveryFee}</div>
                      <div className={classes.headNote}>
                        {theadResource.deliveryFeeNote}
                      </div>
                    </TblHead>
                    <TblHead className={classes.deliveryArrangementFee}>
                      <div>{theadResource.deliveryArrangementFee}</div>
                    </TblHead>
                    <TblHead className={classes.treatmentFee}>
                      <div>
                        {facilityType &&
                          theadResource.treatmentFee[facilityType]}
                      </div>
                      <div className={classes.headNote}>
                        {facilityType &&
                          theadResource.treatmentFeeNote[facilityType]}
                      </div>
                    </TblHead>
                    <TblHead className={classes.totalFee}>
                      {theadResource.totalFee}
                    </TblHead>
                    <TblHead className={classes.status}>
                      {theadResource.status}
                    </TblHead>
                  </tr>
                </thead>
                {appointments.length > 0 && (
                  <tbody>
                    {appointments.map((appo, i) => (
                      <HospitalPaymentRow
                        key={i}
                        appo={appo}
                        facilityType={facilityType}
                        isCentralClinic={isCentralClinic}
                        handleOpenConfirmDialog={this.handleOpenConfirmDialog}
                        handleOpenMedicalFeeDialog={
                          this.handleOpenMedicalFeeDialog
                        }
                        handleOpenPaymentFeeDialog={
                          this.handleOpenPaymentFeeDialog
                        }
                        handleOpenDeliveryInputDialog={
                          this.handleOpenDeliveryInputDialog
                        }
                        handleOpenDeliveryResultDialog={
                          this.handleOpenDeliveryResultDialog
                        }
                        handleOpenDeliveryResetDialog={
                          this.handleOpenDeliveryResetDialog
                        }
                        handleOpenMedicineDeliveryDialog={
                          this.handleOpenMedicineDeliveryDialog
                        }
                        setErrorMessage={this.setErrorMessage}
                      />
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
          {appointments.length === 0 && (
            <div className={classes.divNoData}>
              {Resource.render.div.Typography.j001}
            </div>
          )}
          {isOpenHospitalPaymentConfirm && (
            <HospitalPaymentConfirm
              open={true}
              onClose={this.handleCloseConfirmDialog}
              appointment={appointmentSelect}
              handleCloseConfirmDialog={this.handleCloseConfirmDialog}
            />
          )}
        </div>
        {isOpenMedicalFee && (
          <MedicalFeeDialog
            open={true}
            appointment={appointmentSelect}
            handleChangeMedicalFee={this.handleChangeMedicalFee}
            handleBlurMedicalFee={this.handleBlurMedicalFee}
            handleSetDrugsInfo={this.handleSetDrugsInfo}
            handleChangeBillingCategory={this.handleChangeBillingCategory}
            handleChangeDrugName={this.handleChangeDrugName}
            handleChangeDrugQuantity={this.handleChangeDrugQuantity}
            handleChangeMemo={this.handleChangeMemo}
            handleCloseMedicalFeeDialog={this.handleCloseMedicalFeeDialog}
            loadAppointmentList={this.loadAppointmentList}
            onDialogLoading={this.setDialogLoading}
          />
        )}
        {isOpenPaymentDialog && (
          <PaymentDialog
            open={true}
            handleClickCancel={this.handleClosePaymentDialog}
            requestPayment={this.requestPayment}
            appointment={appointmentSelect}
            errorResource={settleErrorResource}
            handleClosePaymentDialog={this.handleClosePaymentDialog}
            loadAppointmentList={this.loadAppointmentList}
          />
        )}
        {isOpenErrorDialog && (
          <DialogError
            open={true}
            handleCloseDialogError={this.handleCloseDialogError}
          >
            {(errorMessage || " ").split("\n").map((item, index) => (
              <div key={index}>{item}</div>
            ))}
            {isAfterDeliveryError && <YamatoInquiryAnnounce />}
          </DialogError>
        )}
        {appointmentSelect &&
          appointmentSelect.patient &&
          appointmentSelect.patient.address && (
            <React.Fragment>
              <DeliveryProcessInputDialog
                deliveryOption={deliverySelectedOption}
                open={isOpenDeliveryInputDialog}
                appointment={appointmentSelect}
                handleConfirm={this.handleOpenDeliveryConfirmDialog}
                handleClose={this.handleCloseDeliveryInputDialog}
                handleCancel={this.handleOpenCancelDialog}
                handleChangeDeliveryState={this.updateRequestDeliveryState}
                requestDelivery={requestDelivery}
                pickUpDateCandidates={pickUpDateCandidates}
              />
              <DeliveryProcessDialog
                deliveryOption={deliverySelectedOption}
                mode={"confirm"}
                open={isOpenDeliveryConfirmDialog}
                appointment={appointmentSelect}
                requestDelivery={requestDelivery}
                handleCancel={this.handleOpenCancelDialog}
                handleBack={this.handleBackDeliveryInputDialog}
                handleConfirm={this.requestDeliveryProcess}
              />
              <DeliveryProcessDialog
                mode={"result"}
                open={isOpenDeliveryRusultDialog}
                appointment={appointmentSelect}
                handleConfirm={this.handleCsvDownload}
                handleClose={this.handleCloseDeliveryRusultDialog}
              />
              <DeliveryProcessDialog
                mode={"reset"}
                open={isOpenDeliveryResetDialog}
                appointment={appointmentSelect}
                handleCancel={this.handleOpenCancelDialog}
                handleReset={this.resetDeliveryProcess}
              />
              <DialogConfirm
                open={isOpenDeliveryCompleteDialog}
                handleCloseDialogConfirm={
                  this.handleCloseDeliveryCompleteDialog
                }
                title={Resource.render.DialogConfirm.titleYamatoComplete}
                isYamatoComplete={true}
              >
                {Resource.render.DialogConfirm.contentYamatoComplete}
              </DialogConfirm>
              <DialogMedicineDelivery
                open={isOpenMedicineDeliveryDialog}
                appointmentSelect={appointmentSelect}
                handleCloseMedicineDeliveryDialog={
                  this.handleCloseMedicineDeliveryDialog
                }
              />
            </React.Fragment>
          )}
        <Dialog
          open={isLoading}
          fullWidth={true}
          PaperProps={{
            style: {
              willChange: "initial !important",
            },
          }}
        >
          <LoadingScreen isLoading={isLoading} />
        </Dialog>
        <DialogConfirm
          open={isOpenCancelDialogConfirm}
          handleCancelDialogConfirm={this.handleCancelDialogConfirm}
          handleCloseDialogConfirm={this.handleCloseDialogConfirm}
          title={Resource.render.DialogConfirm.title}
        >
          {Resource.render.DialogConfirm.content}
        </DialogConfirm>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(HospitalPaymentList));
